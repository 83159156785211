<template>
      <!--begin header -->
    <header class="header">

        <!--begin nav -->
        <nav class="navbar navbar-default navbar-fixed-top" style="background-color: rgba(34,34,34, 0.95)!important;">
            
            <!--begin container -->
            <div class="container">
        
                <!--begin navbar -->
                <div class="navbar-header">

                    <button v-show="!showNav" @click="showNav = true"  data-target="#navbar-collapse-02" data-toggle="collapse" class="navbar-toggle" type="button">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <button style=" padding: 6px 18px" v-show="showNav" @click="closeNav(); showNav = false" class="navbar-toggle" type="button">
                        <span style="color: orange;">X</span>
                    </button>
                        
                    <!--logo -->
                    <a href="https://www.iduam.com" class="navbar-brand" id="logo"><img src="/images/logo.svg" alt="gallery-image" class="width-100"></a>

                </div>
                        
                <div id="navbar-collapse-02" class="collapse navbar-collapse">
                    <ul class="nav navbar-nav navbar-right">			      
                        <li @click="showNav = true"><router-link :to="{ path: '/' }"  class="nav-link w-nav-link" >Inicio</router-link></li>
<!--                         <li><a @click="scrollToElement('gallery')">videos</a></li> -->
                        <li @click="showNav = false"><router-link :to="{ path: '/', hash: 'gallery' }"  class="nav-link w-nav-link" >videos</router-link></li>
   <!--                      <li><a href="#Servicios">Servicios</a></li> -->
                        <li @click="showNav = false"><router-link :to="{ path: '/', hash: 'aplicacion' }"  class="nav-link w-nav-link">La Aplicación</router-link></li>
                        <li @click="showNav = false"><router-link :to="{ path: '/', hash: 'smartband' }"  class="nav-link w-nav-link">Smartbands</router-link></li>
<!--                         <li @click="showNav = false"><router-link :to="{ path: '/', hash: 'pricing' }"  class="nav-link w-nav-link">Precios</router-link></li> -->
                        <li @click="showNav = false"><a target="_blank" href="https://ventas.iduam.com/" id="linktata" >Compras</a></li>

                        <!--li><a id='buttonInicioSesion' href="#" class="login-bottom">Iniciar Sesión</a></li--> 
                        <li><a  href="https://pacientes.iduam.com/" class="login-bottom" style ="font-size: 10px !important; text-align: center  !important; margin-right: 10px !important;">Iniciar Sesión</a></li>   
<!--                         <li><a  href="https://horas.iduam.com/#/reserva" class="login-bottom" style ="font-size: 10px !important; text-align: center  !important; margin-right: 10px !important;">Toma de horas</a></li> -->   
						<!--li><a  href="https://panel.iduam.com/" class="login-bottom" style ="font-size: 10px !important; text-align: center  !important;">Iniciar Sesión Médico</a></li-->                          
                    </ul>
                </div>
                <!--end navbar -->
                                    
            </div>
    		<!--end container -->
            
        </nav>
    	<!--end nav -->
        
    </header>
    <!--end header -->

</template>

<script>

export default {
  name: "Header",
  data: () => ({
    isMobile: false,
    showNav: false
  }),
  methods: {
    closeNav() {
        $(".navbar-collapse").collapse('hide');
    }
  }
  // beforeDestroy() {
  //   if (typeof window !== "undefined") {
  //     window.removeEventListener("resize", this.onResize, { passive: true });
  //   }
  // },
  // mounted() {
  //   this.onResize();
  //   window.addEventListener("resize", this.onResize, { passive: true });
  // },
  // methods: {
  //   onResize() {
  //     this.isMobile = window.innerWidth < 992;
  //   },
  // },
};
</script>
<style>
</style>