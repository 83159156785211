<template>
   		<!--begin footer -->
		<footer class="visage-footer">

			<!--begin container -->
			<div class="container">


				<!--begin row -->
				<div class="row">
					<br>
					<div class="col-md-4">
<!-- 						<div class="aspire-abouthover wow fadeInRight" style="height: 150px;">
							<div class="aspire-aboutbox2">
								<a href="https://ventasboton.sosclick.cl/"><img src="/images/logo-boton.svg" height="75"></a>
							</div>
						</div> -->
					</div>
					<div class="col-md-4">
						<div class="aspire-abouthover wow fadeInRight" style="height: 150px;">
							<div class="aspire-aboutbox2">
								<a href="https://ventas.iduam.com"><img src="/images/logo_iduam_MP.svg" height="60"></a>
							</div>
						</div>
					</div>
					<div class="col-md-4">
<!-- 						<div class="aspire-abouthover wow fadeInRight" style="height: 150px;">
							<div class="aspire-aboutbox2">
								<a href="https://ventasadultomayor.sosclick.cl/"><img src="/images/LogoAdultoMayor.svg"
										height="75"></a>
							</div>
						</div> -->
					</div>
					<!--begin col md 10 -->
					<div class="col-md-10 col-md-offset-1 text-center">

						<div class="visage-headings">

							<h5 style="color: white;">Cuéntale a tus amigos y recibe fabulosos descuentos en nuestros servicios</h5>
							<img style="width:200px;" src="/images/naturalphone.png" alt="">
						</div>

					</div>
					<!--end col md 10 -->

					<!--begin col md 10 -->
					<div class="col-md-10 col-md-offset-1 text-center margin-bottom-40">

						<!--begin footer_social -->
						<!-- redes sociales
                    <ul class="footer_social">
                        <li>
                            <a href="#" class="social-fb">
                                <i class="fa fa-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
					-->
						<!--end footer_social -->

					</div>
					<!--end col-md-10 -->

					<!--begin col md 4 -->
					<div class="col-md-4 col-sm-4">

						<div class="visage-footer-box">

							<i style="color: white;" class="fa fa-phone"></i>

							<p style="color: white;">Llámanos <br>con tus preguntas</p>

							<span style="color: white;">+56 2 22454622 <br> +56 2 22454618 </span>

						</div>

					</div>
					<!--end col md 4-->

					<!--begin col md 4 -->
					<div class="col-md-4 col-sm-4">

						<div class="visage-footer-box">

							<i style="color: white;" class="fa fa-envelope-o"></i>

							<p style="color: white;">Contáctanos<br> con tus dudas</p>

							<span style="color: white;">contacto@iduam.com</span>

						</div>

					</div>
					<!--end col md 4-->

					<!--begin col md 4 -->
					<div class="col-md-4 col-sm-4">

						<div class="visage-footer-box">

							<i style="color: white;" class="fa fa-map-marker"></i>

							<p style="color: white;">Oficinas<br>Santiago</p>

							<span style="color: white; font-size: 12px">Avenida Kennedy 5735,Hotel Marriott Torre Poniente,Oficina
								1107</span>
							
						</div>

					</div>
							<div style="padding-top: 30px; margin: 0 auto;">
								<router-link style="color: #FFF; " :to="{ path: '/politicas' }"  class="nav-link w-nav-link">- Políticas de Privacidad -</router-link>
							</div>
					<!--end col md 4-->

				</div>
				<!--end row -->

			</div>
			<!--end container -->

		</footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
</style>