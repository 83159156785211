<template>
	<v-layout justify-center align-center style="background-color: #0064F1;height: 100%;">
    <v-snackbar
      v-model="showMessage"
      vertical
      bottom
      center
      :color="messageColor"
    >
      <p class="mb-0">{{message}}</p>
    </v-snackbar>
		<v-container>
			<v-row>
				<v-col cols="12">
					<h3 class="text-center white--text">Ingresa a tu cuenta</h3>
					<v-layout flex-column flex-md-row justify-center align-center style="width: 100%">
						<v-img
							max-height="300"
							max-width="300"
							:src="require('../../assets/logo-iduam.png')"
						></v-img>
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation
							class="d-flex flex-column flex-grow-1"
							style="max-width: 400px;width: 100%;"
						>
							<v-text-field
								v-model="email"
								:rules="emailRules"
								label="Email"
								required
								dark
								class="custom-placeholer-color"
							></v-text-field>
							<v-text-field
								v-model="userPassword"
								:rules="passwordRules"
								label="Contraseña"
								dark
								required
								:append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="() => (passType = !passType)"
								:type="passType ? 'password' : 'text'"
							></v-text-field>
							<v-btn
								:disabled="!valid"
								color="success"
								class="mr-4"
								@click="loginUser"
							>
								Ingresar
							</v-btn>
						</v-form>
					</v-layout>
				</v-col>
			</v-row>
		</v-container>
	</v-layout>
</template>
<script>
	import {login} from '../../components/helpers/api/user'

	export default {
		data: () => ({
			valid: true,
			showMessage: false,
			messageColor: 'error',
			message: '',
			email: '',
			emailRules: [
				v => !!v || 'Email es obligatorio',
				v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
			],
			userPassword: '',
			passwordRules: [
				v => !!v || 'Contraseña es requerida'
			],
			passType: true
		}),
		mounted(){
			console.log(this.$router.currentRoute)
		},
		methods: {
			validate () {
				this.$refs.form.validate()
			},
			reset () {
				this.$refs.form.reset()
			},
			resetValidation () {
				this.$refs.form.resetValidation()
			},
			loginUser () {
				const self = this
				login(this.email, this.userPassword)
					.then(function (resp) {
					if (self.$route.query && self.$route.query.redirect_to) {
						self.$router.push({ path: self.$route.query.redirect_to, query: {avatar_id: self.$route.query.avatar_id}  });
					} 

				else{
					self.$router.push({ path: '/' })
				}
					})
					.catch(function (error) {
						self.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
						if(error.response && error.response.status == 401){
							self.message = 'Email o contraseña incorrectos. Por favor verifica los datos ingresados.'
						}
						self.messageColor = 'warning'
						self.showMessage = true
						console.log(error.response)
					})
			}
		},
	}
</script>
<style>
	.custom-placeholer-color input::placeholder {
		color: red!important;
		opacity: 1;
	}

	.custom-label-color .v-label {
		color: red;
		opacity: 1;
	}
</style>